window.locariTwttr = {
  createTweet(tweetId, elemId) {
    const el = document.getElementById(elemId);

    if (el.dataset.isCreate) {
      return;
    }

    el.dataset.isCreate = true;

    window.twttr.ready((twttr) => {
      try {
        twttr.widgets.createTweet(tweetId, el, { conversation: 'none' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  },
};
