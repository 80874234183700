/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.locari.on_scroll = [];

$(() => {
  let didScroll = false;
  $(window).scroll(() => {
    didScroll = true;
  });

  setInterval(() => {
    if (didScroll) {
      didScroll = false;

      $.each(window.locari.on_scroll, (index, fn) => fn());
    }
  }, 500);
});
