/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */
import Raven from 'raven-js';

window.Raven = Raven;

// なんらかの理由で window.locari が未定義の場合も Sentry にエラーを送る
const shouldSendErrors = !window.locari || window.locari.is_supported_browser;
const LOCARI_PATTERN = /locari\.jp/;

Raven.config(
  'https://6fb6d97f7b1341edb983b5caa3faab9c@app.getsentry.com/35537',
  {
    ignoreErrors: ["Cannot read property 'document' of null"],
    // Only report errors from whole URLs matching a regex pattern or an exact string.
    whitelistUrls: [LOCARI_PATTERN],
    // An array of regex patterns to indicate which URLs are a part of your app in the stack trace.
    includePaths: [LOCARI_PATTERN],

    /**
     * Filter of send data
     * @param  {Object}  _data - paylaod
     * @return {Boolean}       - can send _data if return value is true.
     */
    shouldSendCallback: (_data) => shouldSendErrors,
  }
).install();
