/* global FB */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.fbAsyncInit = () => {
  FB.init({
    appId: '559328734099733',
    status: true,
    xfbml: true,
    version: 'v2.7',
  });
};
