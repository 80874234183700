import jQuery from 'jquery';

window.jQuery = jQuery;
window.$ = jQuery;

import 'jquery-ujs';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import '../web/raven';
import '../shared/locari_twttr';

import '../web/shared';
import '../web/pc';
