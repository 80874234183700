/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  const $searchField = $('#search_keyword_field');
  $searchField.removeAttr('disabled');

  $('#search_form').one('submit', function (e) {
    e.preventDefault();

    const keyword = $searchField.val();
    const default_action = $('#search_default_action').val();

    $searchField.attr('disabled', true);

    const $form = $(this);
    $form.attr('action', `${default_action}/${keyword}`);
    $form.submit();
  });
});
