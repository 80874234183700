/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  $('#search_keyword_field')
    .focusin(() => {
      $('#header_trending_tags').removeClass('hidden');
      return $('#header_trending_tags').animate({ opacity: 1 }, 'fast');
    })
    .focusout(() => {
      $('#header_trending_tags').removeClass('show');
      return $('#header_trending_tags').animate({ opacity: 0 }, 'fast');
    });
});
