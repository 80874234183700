/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './btn_fav';

$(() => {
  if (window.locari.post_id != null) {
    window.locari.btn_fav.setup_btn_fav('posts', window.locari.post_id);
  }

  $('iframe')
    .filter((i, el) => {
      return /(www\.youtube\.com)|(player\.vimeo\.com)/.test(el.src);
    })
    .each((i, el) => {
      $(el).addClass('post-body-embed-movie');
    });
});
