import Hls from 'hls.js';

function makeHlsPlayable(context = document) {
  if (!Hls.isSupported()) {
    return;
  }

  const isHls = (src) => /\.m3u8$/.test(src);

  Array.from(context.querySelectorAll('video')).forEach((video) => {
    const hls = new Hls();
    const { src } = video.dataset;

    if (!isHls(src)) {
      return;
    }

    hls.loadSource(src);
    hls.attachMedia(video);
  });
}

$(() => {
  makeHlsPlayable();

  if (!window.locari.video_autoplay) {
    return;
  }

  window.locari.on_scroll.push(() => {
    const videosToPlay = $(
      'video.instagram-media:in-viewport, .video-media:in-viewport'
    );

    $.each(videosToPlay, (i, el) => {
      if (!el.paused) {
        return;
      }
      const playPromise = el.play();
      if (playPromise !== undefined) {
        playPromise.then(el.classList.add('pausable')).catch((e) => {
          if (e.name !== 'NotAllowedError') {
            window.Raven.captureException(e);
          }
        });
      }
    });

    const videosToPause = $.grep(
      $('video.instagram-media, .video-media, .pausable'),
      (el) => $.inArray(el, videosToPlay) === -1
    );

    $.each(videosToPause, (i, el) => el.pause());
  });
});
