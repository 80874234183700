import Clipboard from 'clipboard';
import 'bootstrap/js/tooltip';

const CLIPBOARD_SELECTOR = '.btn-clipboard';

// MEMO: 複数の要素にはまだ対応していない
const target = document.querySelector(CLIPBOARD_SELECTOR);

if (target) {
  const clipboard = new Clipboard(CLIPBOARD_SELECTOR);

  const showTooltip = (el) => {
    $(el)
      .tooltip('hide')
      .attr('data-original-title', 'コピーしました')
      .tooltip('show');
  };
  const hideTooltip = (el, time = 2000) => {
    setTimeout(() => {
      $(el).tooltip('destroy');
    }, time);
  };

  clipboard.on('success', ({ trigger }) => {
    showTooltip(trigger);
    hideTooltip(trigger);
  });
}
