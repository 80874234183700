/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import 'is-in-viewport';

if (!window.locari.on_scroll) {
  window.locari.on_scroll = [];
}

const FRAME_POSTS = ''; // homeとかは空で問題ないとのこと
const FRAME_POST_FOOTER = 'footer-related';

const campaignIdAndPostIdRegExp = /post_campaign_id=(\d+).+?post_id=(\d+)/;

/*
 * html文字列をパースして記事リストのhtml配列を返します
 *
 * @param {String} html
 * @param {String} frame
 * @return {HtmlElement[]}
 */
const getPostsFromHtmlString = (html, frame) => {
  const postElements = $(`<ul>${html}</ul>`).children();
  return postElements.map((i, postElement) => {
    postElement.setAttribute(
      'class',
      'post post-campaign post--disable-override-list-style'
    );
    postElement.setAttribute('data-imp', '0');
    postElement.setAttribute('data-position', i);
    postElement.setAttribute('data-frame', frame);
    return postElement;
  });
};

/*
 * HtmlElementが持つ href 属性から postId と postCampaignId を抽出して返します
 *
 * @param {jQueryHtmlElement} $post
 * @return {Object} { postId: Number, postCampaignId: Number }
 */
const getImpParams = ($post) => {
  let $link = $post.find('.post-cover-link');

  // 古いテンプレートのセレクタがなければ新しいテンプレートのセレクタで探す
  if (!$link.length) {
    $link = $post.find('.post__inner');
  }

  const href = $link.attr('href');
  const matchResult = href.match(campaignIdAndPostIdRegExp);

  return {
    postCampaignId: Number(matchResult[1]),
    postId: Number(matchResult[2]),
    position: Number($post.attr('data-position')),
    frame: $post.attr('data-frame'),
  };
};

/*
 * active-imp を送信します（エラー処理なし）
 *
 * @param {jQueryHtmlElement[]} $posts  array of postElement
 */
const postActiveImp = ($posts) => {
  const data = { imps: [] };

  $posts.each((i, postElement) => {
    const impParams = getImpParams($($posts[i]));
    data.imps.push({
      post_campaign_id: impParams.postCampaignId,
      post_id: impParams.postId,
      position: impParams.position,
      frame: impParams.frame,
    });

    postElement.setAttribute('data-imp', '1');
  });

  return $.post('https://delivery.locari.jp/v2/imp', JSON.stringify(data));
};

/*
 * Viewableなキャンペーン記事要素を返します
 *
 * @param {jQueryHtmlElement} $context  検索対象の要素
 * @return {jQueryHtmlElement[]}
 */
const getViewablePostCampaigns = ($context) =>
  $('.post-campaign:in-viewport[data-imp="0"]', $context);

/*
 * キャンペーン記事がViewableになったときにImpを送信するハンドラを登録します
 *
 * @param {jQueryHtmlElement} _$context
 */
const bindInViewportHandler = (_$context) => {
  window.locari.on_scroll.push(($context) => {
    const $posts = getViewablePostCampaigns($context);

    if (!$posts.length) {
      return;
    }

    postActiveImp($posts);
  });
};

/*
 * PCTopのピックアップ4件目に広告記事を挿入します
 *
 * @param {jQueryHtmlElement} $adPost
 * @return {Boolean} PCTopで広告を挿入できたらtrueを返します
 */
const injectAdToPickupPosts = ($adPost) => {
  const $postContainer = $('.pickup-posts__container .posts');

  if (!$postContainer.length) {
    return false;
  }

  $postContainer.find('li').eq(2).after($adPost);

  bindInViewportHandler($postContainer);

  return true;
};

/*
 * $section内に広告記事を挿入します
 *
 * @param {jQueryHtmlElement} $adPosts
 * @param {jQueryHtmlElement} $section
 */
const injectAdsToPostList = ($adPosts, $section) => {
  let injectCount = 0;
  let nextInjectIndex = 4;
  const $injectTargetPosts = $section.find('.posts li');

  // 記事4つにつき1つ広告を挿入する
  $injectTargetPosts.each((i, $post) => {
    const $adPost = $adPosts[injectCount];
    if (i + 1 === nextInjectIndex && $adPost) {
      $post.after($adPost);
      nextInjectIndex += 4;
      injectCount += 1;
    }
  });
  return bindInViewportHandler($section);
};

/*
 * 記事詳細のフッターにキャンペーン記事を挿入します
 */
const injectAdsToFooter = () => {
  $.get('/web_api/post_campaigns', { limit: 4 }, (body) => {
    if (!body) {
      return;
    }

    const $posts = getPostsFromHtmlString(body, FRAME_POST_FOOTER);

    if ($posts.length >= 2) {
      const $relationalPostsSection = $('#post_footer_relational_posts');
      $relationalPostsSection.find('ul').append($posts.slice(0, 2));
      bindInViewportHandler($relationalPostsSection);
    }

    if ($posts.length >= 4) {
      const $featuredPostsSection = $('#post_footer_featured_posts');
      $featuredPostsSection.find('ul').append($posts.slice(2, 4));
      bindInViewportHandler($featuredPostsSection);
    }
  });
};

/*
 * Homeなどの記事一覧にキャンペーン記事を挿入します
 *
 * @param {jQueryHtmlElement} $section
 * @param {Function} callback  記事挿入後に実行する
 */
const injectAdsToPosts = ($section, callback) => {
  let cb = callback;
  if (cb == null) {
    cb = () => {};
  }

  const params = {
    limit: 5,
  };

  return $.get('/web_api/post_campaigns', params, (body) => {
    if (!body) {
      return;
    }

    const $adPosts = getPostsFromHtmlString(body, FRAME_POSTS);

    let sliceStartIndex = 0;
    if (injectAdToPickupPosts($adPosts.first())) {
      sliceStartIndex = 1;
    }

    injectAdsToPostList(
      $adPosts.slice(sliceStartIndex, $adPosts.length),
      $section
    );

    cb();
  });
};

/*
 * エントリーポイント
 */
$(() => {
  if (window.locari.no_ads) {
    return;
  }

  // 記事詳細
  if (window.locari.post_id) {
    injectAdsToFooter();
  }

  // home, category
  const $section = $('#posts_ad_injectable');
  if ($section.length) {
    const checkHasCampaignOnFirstView = () => {
      const $campaigns = getViewablePostCampaigns($section);
      if ($campaigns.length) {
        postActiveImp($campaigns);
      }
    };

    injectAdsToPosts($section, checkHasCampaignOnFirstView);
  }
});
