function checkVisibility() {
  const elements = document.querySelectorAll('.animation');
  const windowHeight = window.innerHeight;

  elements.forEach((element) => {
    const rect = element.getBoundingClientRect();
    if (rect.top < windowHeight && rect.bottom >= 0) {
      element.classList.add('--animated');
    }
  });
}

window.addEventListener('scroll', checkVisibility);
window.addEventListener('load', checkVisibility);
