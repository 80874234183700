/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.locari) {
  window.locari = {};
}
if (!window.locari.btn_fav) {
  window.locari.btn_fav = {};
}

// Internal: Intialize a button
//
// favorited_count - The Integer number of times to favorited posts.
//
// Returns nothing.
const init_fav_button = (favorited_count) => {
  if (favorited_count >= 100) {
    $('.fav-async-count').text(` ${favorited_count}`);
    $('.fav-async').removeClass('hide');
  } else {
    $('.fav-async').addClass('hide');
  }
};

// Internal: Setup buttons' events
//
// plural_type - The String which is pluralized Class name.
//
// Returns nothing.
const init_fav_event = (plural_type, record_id) => {
  const $link = $('.btn-fav');
  const signed_in = $('.btn-fav').hasClass('btn-fav-sign-in');

  if (signed_in) {
    $link.click((e) => {
      e.preventDefault();
      const favorited = $('.btn-fav').hasClass('done');

      if (favorited) {
        $.post(`/web_api/${plural_type}/${record_id}/unfavorite`, (data) =>
          window.locari.btn_fav.unfavorited_post(data.favorited_count)
        );
      } else {
        $.post(`/web_api/${plural_type}/${record_id}/favorite`, (data) =>
          window.locari.btn_fav.favorited_post(data.favorited_count)
        );
      }
    });
  } else if (!window.locari.is_mobile) {
    $link.leanModal({ overlay: 0.4, closeButton: '.close-modal' });
  }
};

// Public: Update a button as favorited
//
// favorited_count - The Integer number of times to favorited posts.
//
// Returns nothing.
window.locari.btn_fav.favorited_post = (favorited_count) => {
  init_fav_button(favorited_count);
  $('.btn-fav').addClass('done');
};

// Public: Update a button as unfavorited
//
// favorited_count - The Integer number of times to favorited posts.
//
// Returns nothing.
window.locari.btn_fav.unfavorited_post = (favorited_count) => {
  init_fav_button(favorited_count);
  $('.btn-fav').removeClass('done');
};

window.locari.btn_fav.setup_btn_fav = (plural_type, record_id) => {
  $.get(`/web_api/${plural_type}/${record_id}/favorited`, (data) => {
    init_fav_button(data.favorited_count);

    if (data.favorited) {
      $('.btn-fav').addClass('done');
    }

    init_fav_event(plural_type, record_id);
  });
};
