/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import fastclick from 'fastclick';

$(() => {
  fastclick.attach(document.body);

  const $side_menu = $('#side_menu');
  if ($side_menu.length) {
    const $content_outer = $('#content_outer');
    const $close_button = $('#close_button');
    const $menu_trigger = $('#menu_trigger');

    $menu_trigger.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const content_height = $('body').height();

      $side_menu.addClass('side-menu-opened');
      $content_outer.addClass('container-side-menu-opened');

      $(
        `<style> #content_outer.container-side-menu-opened::after { height: ${content_height}px; } </style>`
      ).appendTo('head');

      const content_click_handler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        // close menu
        $side_menu.removeClass('side-menu-opened');
        $content_outer.removeClass('container-side-menu-opened');

        $content_outer.off('click', content_click_handler);
      };

      $content_outer.on('click', content_click_handler);
      $close_button.on('click', content_click_handler);
    });
  }
});
