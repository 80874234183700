(function () {
  const hideUnsupportedBrowsersAlert = (e) => {
    const dismiss = e.currentTarget;
    const alertContent = document.querySelector('.unsupported-browser');
    alertContent.classList.add('hide');
    dismiss.removeEventListener('click', hideUnsupportedBrowsersAlert);
  };

  const dismiss = document.querySelector('.unsupported-browser__dismiss');

  if (dismiss) {
    dismiss.addEventListener('click', hideUnsupportedBrowsersAlert);
  }
})();
