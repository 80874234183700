/**
 * @return {Element[]}
 */
function getContainer() {
  return Array.from(
    document.querySelectorAll('.post-element-tweet-container[data-tweet-id]')
  );
}

function embedTwitterElement() {
  getContainer().forEach((el) => {
    const { tweetId } = el.dataset;
    const targetElementId = el.id;

    window.locariTwttr.createTweet(tweetId, targetElementId);
  });
}

embedTwitterElement();
